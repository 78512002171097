<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="p-4 text-gray-100">
              <div>
                <a
                  v-on:click="$router.go(-1)"
                  class="flex items-center space-x-2 text-center cursor-pointer"
                  ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
                >
              </div>
            </div>

            <div class="mt-5">
              <!-- <div>
                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-40 md:h-80 lg:h-96 w-full"
                  :src="img_cover"
                  alt=""
                  v-if="img_cover"
                />

                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-40 md:h-80 lg:h-96 w-full"
                  src="https://promptdue.com/img/home.png"
                  alt=""
                  v-else
                />
              </div> -->

              <div class="">
                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-20 w-20 sm:w-32 sm:h-32 border-2 rounded-full border-red-500"
                  :src="img_profile"
                  alt=""
                  v-if="img_profile"
                />

                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-20 w-20 sm:w-32 sm:h-32 border-2 rounded-full border-red-500"
                  src="https://media.discordapp.net/attachments/1080058777971212370/1083317410674769940/PromptDue_Logo_64x64px_02.png?width=128&height=128"
                  alt=""
                  v-else
                />
              </div>

              <div class="mt-5">
                <h3 class="text-sm font-bold text-gray-100">
                  Image Profile Link
                </h3>

                <div
                  class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2"
                >
                  <input
                    placeholder="Image Profile Link"
                    class="flex-grow outline-none text-sm bg-transparent text-white"
                    v-model="img_profile"
                  />
                </div>
              </div>

              <!-- <div class="mt-5">
                <h3 class="text-sm font-bold text-gray-100">
                  Image Cover Link
                </h3>

                <div
                  class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2"
                >
                  <input
                    placeholder="Image Cover Link"
                    class="flex-grow outline-none text-sm bg-transparent text-white"
                    v-model="img_cover"
                  />
                </div>
              </div> -->

              <div class="">
                <div>
                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">Name</h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2"
                    >
                      <input
                        placeholder="Name"
                        class="flex-grow outline-none text-sm bg-transparent text-white"
                        v-model="name"
                      />
                    </div>
                  </div>

                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">Description</h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2 text-white"
                    >
                      <textarea
                        v-model="description"
                        rows="4"
                        class="flex-grow outline-none text-sm bg-transparent"
                        placeholder="Write your thoughts here..."
                      ></textarea>
                    </div>
                  </div>

                  <!-- <div class="text-gray-200 mt-5 flex">
                      <h3 class="text-sm font-bold">
                        <i class="fas fa-eye"></i><b class="ml-1">10</b>
                      </h3>
                      <h3 class="text-sm font-bold ml-2">
                        <i class="fas fa-heart ml-2"></i><b class="ml-1">10</b>
                      </h3>
                      <h3 class="text-sm font-bold ml-2">
                        <i class="fas fa-tags ml-2"></i><b class="ml-1">10</b>
                      </h3>
                    </div> -->

                  <h3 class="text-lg font-bold text-white mt-10">Social</h3>

                  <div class="text-gray-200 mt-2 flex">
                    <h3 class="text-3xl font-bold">
                      <i class="fab fa-facebook-square"></i>
                    </h3>
                    <h3 class="text-3xl font-bold ml-3">
                      <i class="fab fa-telegram"></i>
                    </h3>
                    <h3 class="text-3xl font-bold ml-3">
                      <i class="fab fa-instagram"></i>
                    </h3>
                  </div>

                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">
                      Facebook Link
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2"
                    >
                      <input
                        placeholder="Facebook Link"
                        class="flex-grow outline-none text-sm bg-transparent text-white"
                        v-model="facebook"
                      />
                    </div>
                  </div>
                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">
                      Telegram Link
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2"
                    >
                      <input
                        placeholder="Telegram Link"
                        class="flex-grow outline-none text-sm bg-transparent text-white"
                        v-model="telegram"
                      />
                    </div>
                  </div>

                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">
                      Instagram Link
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg mt-2"
                    >
                      <input
                        placeholder="Instagram Link"
                        class="flex-grow outline-none text-sm bg-transparent text-white"
                        v-model="instagram"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="flex flex-col items-center mt-14">
                  <button
                    @click="UpDateUser()"
                    class="w-42 mt-2 flex-none text-2xl text-base px-6 py-2 border rounded text-gray-700 border-none bg-gradient-to-r from-green-500 to-blue-300"
                  >
                    Save and Public
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StickFooter />
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import User from "@/api/user";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      address: this.$route.params.address || null,
      img_profile: "",
      img_cover: "",
      name: "name",
      description: "",
      facebook: "",
      telegram: "",
      instagram: "",
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions(["setUser"]),
    async UpDateUser() {
      await User.update({
        user_id: this.getUser._id,
        img_profile: this.img_profile,
        img_cover: this.img_cover,
        name: this.name,
        description: this.description,
        facebook: this.facebook,
        telegram: this.telegram,
        instagram: this.instagram,
      })
        .then((res) => {
          this.setUser(res.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Update success",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.$router.push("/account/" + this.getUser.address);
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (this.getUser.img_profile) {
      this.img_profile = this.getUser.img_profile;
    }
    if (this.getUser.img_cover) {
      this.img_cover = this.getUser.img_cover;
    }

    if (this.getUser.name) {
      this.name = this.getUser.name;
    } else {
      this.name = this.getUser.address;
    }

    if (this.getUser.description) {
      this.description = this.getUser.description;
    }
    if (this.getUser.facebook) {
      this.facebook = this.getUser.facebook;
    }
    if (this.getUser.telegram) {
      this.telegram = this.getUser.telegram;
    }

    if (this.getUser.instagram) {
      this.instagram = this.getUser.instagram;
    }
  },
};
</script>
